<template>
  <div>
    <!-- <template>
			<el-table :fileList=tableInfo.fieldList>
				<el-form-item v-for="list,index in tableInfo.fieldList" :key="index" :label="list.label" :value="list.value"></el-form-item>
			</el-table>
		</template> -->
    <!-- <span class="title-top">供应求购</span> -->
    <template>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane class="table-content-pane" label="供应信息" name="first">
          <div class="content">
            <div>
              <!-- 获取书籍列表 -->
              <el-table :data="supply" style="width: 100%;padding-left: 20px;" @row-click="toDetail">
                <el-table-column prop="cover" label="书籍" width="180">
                  <template slot-scope="scope">
                    <el-image style="width: 130px; height: 160px" :src="scope.row.cover" fit="cover">
                    </el-image>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="书名" width="300">
                </el-table-column>
                <el-table-column prop="authorName" label="作者">
                </el-table-column>
                <el-table-column prop="categoryName" label="分类">
                </el-table-column>
                <el-table-column prop="bottomPrice" label="价格" width="100">
                </el-table-column>
                <el-table-column>
                  <el-row>
                    <el-button :round="true" class="table-content-button">详情</el-button>
                  </el-row>
                </el-table-column>
              </el-table>
              <div class="table-bottom">
                <el-row type="flex" justify="center">
                  <el-col>
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
                      :current-page.sync="supPage.pageNum" :page-size="supPage.pageSize" layout="prev, pager, next"
                      :total="supPage.total">
                    </el-pagination>
                  </el-col>
                  <el-col style="text-align: left;">
                    <el-button class="table-page-button" @click="nextPage">下一页</el-button>
                    <el-button class="table-page-button" @click="lastPage">尾页</el-button>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane class="table-content-pane" label="求购信息" name="second">
          <div class="content">
            <div>
              <el-table :data="book" style="width: 100%;padding-left: 20px" @row-click="toDetail">
                <el-table-column label="书籍" width="180">
                  <template slot-scope="scope">
                    <el-image style="width: 130px; height: 160px" :src="scope.row.cover" fit="cover">
                    </el-image>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="书名" width="300">
                </el-table-column>
                <el-table-column prop="authorName" label="作者">
                </el-table-column>
                <el-table-column prop="categoryName" label="分类">
                </el-table-column>
                <el-table-column prop="topPrice" label="价格" width="100">
                </el-table-column>
                <el-table-column>
                  <el-row>
                    <el-button :round="true" class="table-content-button">联系TA</el-button>
                  </el-row>
                </el-table-column>
              </el-table>
              <div class="table-bottom">
                <el-row type="flex" justify="center">
                  <el-col>
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
                      :current-page.sync="bookPage.pageNum" :page-size="bookPage.pageSize" layout="prev, pager, next"
                      :total="bookPage.total">
                    </el-pagination>
                  </el-col>
                  <el-col style="text-align: left;">
                    <el-button class="table-page-button" @click="nextPage">下一页</el-button>
                    <el-button class="table-page-button" @click="lastPage">尾页</el-button>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </template>
  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        supply: [],
        activeName: "first",
        book: [],
        supPage: {
          total: 0,
          pageSize: 5,
          pageNum: 1,
          categoryId: "",
          searchText: "",
        },
        bookPage: {
          total: 0,
          pageSize: 5,
          pageNum: 1,
          categoryId: "",
          searchText: "",
        },
        category: {
          pageNum: 1,
          pageSize: 5,
          categoryId: "",
          searchText: "",
        },
      }
    },
    created() {
      this.supplyList(this.supPage)
    },
    watch: {
      "$route": {
        handler(val) {
          this.supPage.categoryId = val.params.id
          this.bookPage.categoryId = val.params.id
          // this.category.name = val.params.name
          this.supplyList(this.supPage);
          this.bookList(this.bookPage)
        }
      },
      "$store.state.searchText": {
        handler(val) {
          // this.category.searchText = val
          this.supPage.searchText = val;
          this.bookPage.searchText = val
          // this.category.name = val
          this.handleClick()
          this.supPage.pageNum = 1;
          this.bookPage.pageNum = 1;
        }
      }
    },
    methods: {
      // 切换pane
      handleClick() {
        if (this.activeName == 'first') {
          this.supplyList(this.supPage);
        }
        if (this.activeName == 'second') {
          this.bookList(this.bookPage)
        }
      },
      
      // 供应
      async supplyList(val) {
        const data = await this.$Axios.getSupplyList(val);
        console.log("data.data",data.data)
        this.supply = data.data.map(v => {v.bottomPrice = "面议";return v});
        this.supPage.total = data.total
      },
      // 求购
      async bookList(val) {
        const data = await this.$Axios.getBookList(val);
        this.book = data.data.map(v => {v.topPrice = "面议";return v});
        this.bookPage.total = data.total
      },
      // 详情
      toDetail(row) {
        this.$router.push({
          path: "/detail",
          query: {
            id: row.id,
            activeName: this.activeName
          },
        });
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条` + val);
      },
      // 页码改变
      handleCurrentChange(val) {
        this.category.pageNum = val
        this.supPage.pageNum = val
        this.bookPage.pageNum = val
        this.supplyList(this.supPage)
        this.bookList(this.bookPage)
      },
      // 下一页
      nextPage() {
        if (this.activeName == 'first') {
          ++this.supPage.pageNum;
          this.category.pageNum = this.supPage.pageNum
          this.supplyList(this.supPage)
        }
        if (this.activeName == 'second') {
          ++this.bookPage.pageNum;
          this.category.pageNum = this.bookPage.pageNum
          this.bookList(this.bookPage)
        }
      },
      // 尾页
      lastPage() {
        if (this.activeName == 'first') {
          let supMax = 0;
          if (this.supPage.total > this.supPage.pageSize) {
            supMax = this.supPage.total / this.supPage.pageSize
            if (this.supPage.total % this.supPage.pageSize > 0) {
              ++supMax;
            }
          } else {
            supMax = 1;
          }
          this.supPage.pageNum = supMax
          this.supplyList(this.supPage)
        }
        if (this.activeName == 'second') {
          let bookMax = 0;
          if (this.bookPage.total > this.bookPage.pageSize) {
            bookMax = this.bookPage.total / this.bookPage.pageSize
            if (this.bookPage.total % this.bookPage.pageSize > 0) {
              ++bookMax;
            }
          } else {
            bookMax = 1;
          }
          this.bookPage.pageNum = bookMax
          this.supplyList(this.bookPage)
        }
      }
    }
  }
</script>

<style>
  .table-top {
    text-align: right;
    position: relative;
    right: 40px;
    top: 35px;
  }
  
  .title-top {
    width: 80px;
    height: 28px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    line-height: 24px;
    color: #413434;
    opacity: 1;
  }

  .table-top-button {
    z-index: 50;
    position: relative;
    left: 1500px;
    top: 35px;
    margin-left: 100px;
    height: 40px;
    width: 76px;
    background-color: #13BBB9;
    color: #FFFFFF;
  }

  .content {
    z-index: -1;
    padding-left: 10px;
    padding-right: 60px;
  }
  
  .cell {
    /* width: 282px; */
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: #1A3664;
    opacity: 1;
    /* border-bottom: 0px; */
  }

  .el-table td, .el-table th.is-leaf {
      border-bottom: 0px;
  }

  .table-content-button {
    color: #FFFFFF;
    background-color: #F5B300;
  }

  .table-page-num {
    width: 35px;
    height: 35px;
    background: #FFFFFF;
    border: 1px solid #E8E9EC;
    opacity: 1;
    text-align: center;
  }

  .table-page-button {
    height: 33px;
    color: #43425D;
    opacity: 1;
    border-radius: 4px;
    align-content: left;
  }

  .el-tabs__item.is-active {
    color: #13bbb9;
    opacity: 0.49;
    font-weight: bold;
  }
  
  .table-bottom {
    height: 70px;
    text-align: right;
    background-color: #FFFFFF;
  }
  
  .el-table::before {
    height: 0px;
  }
  
  .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li {
    background-color: #FFFFFF;
  }
</style>
